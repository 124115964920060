<template>
  <div>
    <FileUploadModel
      :uploadFileListTitle="$t('general.router-terminal-import-param')"
      :uploadFileListName="$t('terminal.param-import-list')"
      :uploadURL="'/rest/terminal/importParam'"
      :extensionName="['xls', 'xlsx']"
      :acceptFileTypes="'.xls,.xlsx'"
      :templateURL="'/tempFiles/TerminalParamTemplate.xlsx'"
      :templateName="'TerminalParamTemplate.xlsx'"
      :registerResultTitle="'general.result-t'"
      :isShowBack="true"
    />
  </div>
</template>

<script setup></script>
